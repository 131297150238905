import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
// axios
import axios from "axios";
import { setAuthTokens } from "axios-jwt";
import * as forge from "node-forge";
// Router
import { Link, useHistory } from "react-router-dom";
import { useTracking } from "react-tracking";
// Utils
import { emailRegexValidate } from "../../../utilities/validators/inputValidators";
import posthog from "posthog-js";
import apiEndpointList from "../../../config/core_banking/endpoint";
import {
  APIConfig,
  AuthResponseToAuthTokens,
} from "../../../services/apiConfigurationOtp";
import { randomUUID } from "../../../services/randomUUID";
import { capturePosthogEvent } from "../../../utilities/posthogUtils";
import { capturePosthogEventCustom } from "../../../utilities/postHogCustomUtils";
// Components
import SnackbarMessage from "../../../components/Shared/SnackbarMessage/SnackbarMessage";
// Contexts
import LoginContext from "../../../contexts/LoginContext";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const OTPLogin = () => {
  // Posthog event capture on page visit
  useEffect(() => {
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      if (window?.location?.origin && window?.location?.pathname) {
        try {
          capturePosthogEventCustom(
            window.location.origin,
            window.location.pathname,
            "Login Page View",
            {
              additionalInfo: "User viewed Login page",
            }
          );
        } catch (error) {
          console.error("Error capturing Posthog event:", error);
        }
      } else {
        console.warn(
          "Unable to capture event: window.location properties are not fully defined"
        );
      }
    }
  }, []);
  let history = useHistory();

  const { trackEvent } = useTracking();

  // ^ context
  const {
    toVerifyOTP,
    setUserOtpTransactionID,
    setLoginDetails,
    setOtpSentMessage,
    requestUrn,
    setRequestUrn,
  } = useContext(LoginContext);
  // ^ btn loading state
  const [btnText, setBtnText] = useState("");

  const [userData, setUserData] = useState({
    email: "",
    password: "",
  });

  // const errors = {
  //   email: "",
  //   password: "",
  // };

  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const requiredFieldErrors = {
    email: "Email is required",
    password: "Password is required",
  };

  const invalidFieldErrors = {
    email: "Please enter valid email",
  };

  const handleChange = (event) => {
    setUserData({
      ...userData,
      [event.currentTarget.name]: event.currentTarget.value,
    });
  };

  const validateInputs = () => {
    let valid = true;
    const newErrors = { ...errors };
    for (const key in userData) {
      if (!userData[key]) {
        newErrors[key] = requiredFieldErrors[key];
        valid = false;
      } else if (key === "email" && !emailRegexValidate(userData[key])) {
        newErrors[key] = invalidFieldErrors[key];
        valid = false;
      } else {
        newErrors[key] = "";
      }
    }
    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      process.env.REACT_APP_ENV_NAME === "staging" ||
      process.env.REACT_APP_ENV_NAME === "production"
    ) {
      capturePosthogEvent(
        window.location.origin,
        window.location.pathname,
        "cta"
      );
    }
    const publicKey = process.env.REACT_APP_AUTH_ENCRYPTION_PUBLIC_KEY;
    const forgedPublicKey = forge.pki.publicKeyFromPem(publicKey);
    let encryptedPassword = forgedPublicKey.encrypt(
      userData.password,
      "RSA-OAEP",
      {
        md: forge.md.sha256.create(),
        mgf1: forge.mgf1.create(),
      }
    );
    const finalEncryptedPassword = forge.util.encode64(encryptedPassword);
    if (validateInputs()) {
      localStorage.clear();

      // * Loading state
      setBtnText("loading");

      axios
        // .post(APIConfig.BASE_URL + "/v2/auth/user/otp", {
        .post("https://in.decentro.tech" + "/v2/auth/token", {
          grant_type: "user_credentials",
          email: userData.email.toLowerCase(),
          encrypted_password: finalEncryptedPassword,
        })
        .then(async (response) => {
          // if (response?.data?.responseKey === "success_login") {
          if (response?.data?.response_key === "success_login") {
            setAuthTokens(AuthResponseToAuthTokens(response.data));

            localStorage.setItem(
              "user",
              JSON.stringify({
                name: response.data.company_common_name,
                email: response.data.email,
                client_id: response.data.client_id,
              })
            );
            localStorage.setItem("loggedInFlag", true);
            if (
              process.env.REACT_APP_ENV_NAME === "staging" ||
              process.env.REACT_APP_ENV_NAME === "production"
            ) {
              capturePosthogEvent(
                window.location.origin,
                window.location.pathname,
                "submit"
              );
            }
            getRegisteredEmail();

            history.push("/dashboard");
          }

          // if (response?.data?.responseKey === "success_generate_otp_sms") {
          if (response?.data?.response_key === "success_initiate_auth_sms") {
            // * Storing the mobile number message
            setOtpSentMessage(response?.data?.response_message);
            setRequestUrn(response?.data?.request_urn);
            // * Card changed to OTP verify screen
            toVerifyOTP();

            // * snackbar message
            ReactDOM.render(
              <SnackbarMessage
                msgtype="success"
                msg={"OTP sent successfully"}
              />,
              document.getElementById("snackbar")
            );
          }

          // * Loading state
          setBtnText("");

          // * Store Transition ID
          setUserOtpTransactionID(response.data.decentroTxnId);

          // * Store Login Details
          setLoginDetails({
            email: userData.email.toLowerCase(),
            password: userData.password,
          });
        })
        .catch((err) => {
          // * Loading state
          setBtnText("");
          // ReactDOM.render(
          //   <SnackbarMessage msgtype="error" msg={err.response.data.message} />,
          //   document.getElementById("snackbar")
          // );
          ReactDOM.render(
            <SnackbarMessage
              msgtype="error"
              msg={err.response.data.response_message}
            />,
            document.getElementById("snackbar")
          );
        });
    }
  };

  // ^ Get registered Email's
  function getRegisteredEmail() {
    APIConfig.API_Client.post(
      apiEndpointList.ACCOUNTS_REGISTERED_EMAIL_REPORTS.baseUrl +
        apiEndpointList.ACCOUNTS_REGISTERED_EMAIL_REPORTS.endpoint,
      {},
      { cancelToken: source.token }
    )
      .then((response) => {
        if (response.length === 0) {
          localStorage.setItem("reportsDefaultEmail", null);
          localStorage.setItem("commonName", null);
        } else {
          localStorage.setItem(
            "reportsDefaultEmail",
            response?.data[0]?.email_id
          );

          localStorage.setItem("commonName", response?.data[0]?.common_name);
        }
      })
      .catch((error) => {});
  }

  return (
    <div className="form-section">
      <img
        className="fixed-logo-new"
        src="https://decentro-icons.s3.ap-south-1.amazonaws.com/forget-password-dashboard/decentro-blue-bg-logo.svg"
        alt="logo"
      />
      <h1>Welcome back! Please login to your account.</h1>
      <div className="input-section">
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="inputs">
            <div className="input-field">
              <label>Email</label>
              <input
                className="type1"
                type="email"
                name="email"
                placeholder="Enter your email"
                onChange={handleChange}
                value={userData.email}
                required
              />
              <span className="error" id="email_error">
                {errors.email}
              </span>
            </div>

            <div className="input-field">
              <label>Password</label>
              <input
                className="type1"
                type="password"
                name="password"
                placeholder="Password"
                onChange={handleChange}
                value={userData.password}
                required
              />
              <span className="error" id="password_error">
                {errors.password}
              </span>
            </div>
          </div>
          <div className="forgot-your-password">
            <Link to="/forgot-password">Forgot your password?</Link>
          </div>
          <button className="login-button" type="submit">
            {btnText === "loading" ? "Loading ..." : "Submit"}
          </button>
        </form>
        {/* <div className="signup-message">
          <p>
            Don't have an account yet? <Link to="/signup">SIGN UP</Link>
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default OTPLogin;
