import React, { useContext, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { useHistory } from "react-router-dom";
import { randomUUID } from "../../../services/randomUUID";
import { otpRegexValidate } from "../../../utilities/validators/inputValidators";
// api config imports
import axios from "axios";
import { setAuthTokens } from "axios-jwt";
import SnackbarMessage from "../../../components/Shared/SnackbarMessage/SnackbarMessage";
import apiEndpointList from "../../../config/core_banking/endpoint";
import LoginContext from "../../../contexts/LoginContext";
import {
  APIConfig,
  AuthResponseToAuthTokens,
} from "../../../services/apiConfigurationOtp";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

const OTPVerify = () => {
  let history = useHistory();
  // ^ context
  const {
    userOtpTransactionID,
    toLoginOTP,
    resendOTPApi,
    otpSentMessage,
    requestUrn,
  } = useContext(LoginContext);

  // & input field values
  const [userData, setUserData] = useState({
    otp: "",
  });

  // & resend btn state
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  // & resend btn timer state
  const [timer, setTimer] = useState(30); // Initial timer value in seconds
  // & attempts count state
  const [otpAttemptCount, setOtpAttemptCount] = useState(3); // For OTP wrong attempts
  const [resendAttemptCount, setResendAttemptCount] = useState(5); // For resend attempts
  // & btn loading state
  const [btnText, setBtnText] = useState("");

  // & resend btn click handler
  useEffect(() => {
    let interval;

    if (isButtonDisabled) {
      interval = setInterval(() => {
        setTimer((prevTimer) => prevTimer - 1);
      }, 1000);

      if (timer === 0) {
        // Enable the button after 30 seconds
        setIsButtonDisabled(false);
        clearInterval(interval);
      }
    }

    return () => {
      clearInterval(interval);
    };
  }, [isButtonDisabled, timer]);

  const handleClick = () => {
    // Clear Input
    setUserData({
      otp: "",
    });

    // Disable the button and reset the timer
    setIsButtonDisabled(true);
    setTimer(30);

    // Decrease resend attempt count when resend is clicked
    setResendAttemptCount((prevCount) => prevCount - 1);

    // Decrease OTP attempt count when resend is clicked
    // setOtpAttemptCount((prevCount) => prevCount - 1);

    // Enable the button after 30 seconds
    setTimeout(() => {
      setIsButtonDisabled(false);
    }, 30000); // 30 seconds in milliseconds
  };

  // ^ Get registered Email's
  function getRegisteredEmail() {
    APIConfig.API_Client.post(
      apiEndpointList.ACCOUNTS_REGISTERED_EMAIL_REPORTS.baseUrl +
        apiEndpointList.ACCOUNTS_REGISTERED_EMAIL_REPORTS.endpoint,
      {},
      { cancelToken: source.token }
    )
      .then((response) => {
        if (response.data.length === 0) {
          localStorage.setItem("reportsDefaultEmail", null);
          localStorage.setItem("commonName", null);
        } else {
          localStorage.setItem(
            "reportsDefaultEmail",
            response?.data[0]?.email_id
          );

          localStorage.setItem("commonName", response?.data[0]?.common_name);
        }
      })
      .catch((error) => {});
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    if (validateInputs()) {
      // * Loading state
      setBtnText("loading");

      // Decrease OTP attempt count when submitting OTP
      setOtpAttemptCount((prevCount) => prevCount - 1);

      //* Verify OTP API call
      axios
        .post("https://in.decentro.tech" + "/v2/mfa/verify", {
          request_urn: requestUrn,
          otp: userData.otp,
        })
        .then((response) => {
          // setAuthTokens(AuthResponseToAuthTokens(response.data.data));
          setAuthTokens(AuthResponseToAuthTokens(response.data));

          // * Loading state
          setBtnText("");

          localStorage.setItem(
            "user",
            JSON.stringify({
              name: response.data.company_common_name,
              email: response.data.email,
              client_id: response.data.client_id,
            })
          );

          localStorage.setItem("loggedInFlag", true);

          // * Get registered email for cas default email id
          getRegisteredEmail();

          // * Back to Default Card
          toLoginOTP();

          history.push("/dashboard");
        })
        .catch((err) => {
          // * Loading state
          setBtnText("");

          if (otpAttemptCount <= 1) {
            toLoginOTP();
          }

          ReactDOM.render(
            <SnackbarMessage
              msgtype="error"
              msg={
                err?.response?.data?.response_message || "Something went wrong"
              }
            />,
            document.getElementById("snackbar")
          );
        });
    }
  };

  const [errors, setErrors] = useState({
    otp: "",
  });

  const requiredFieldErrors = {
    otp: "OTP is required",
  };

  const invalidFieldErrors = {
    otp: "Please enter a valid OTP",
  };

  const handleChange = (event) => {
    // Restrict input to numeric characters only
    const numericInput = event.target.value.replace(/\D/, "");
    setUserData({
      ...userData,
      otp: numericInput,
    });
  };

  const validateInputs = () => {
    let valid = true;
    const newErrors = { ...errors };
    if (!userData.otp) {
      newErrors.otp = requiredFieldErrors.otp;
      valid = false;
    } else if (!otpRegexValidate(userData.otp)) {
      newErrors.otp = invalidFieldErrors.otp;
      valid = false;
    } else {
      newErrors.otp = "";
    }
    setErrors(newErrors);
    return valid;
  };

  const mobileNumberMessage = otpSentMessage?.substring(
    otpSentMessage?.length - 3
  );

  const VerifyHeaderText = mobileNumberMessage ? (
    <h1 className="includes-registered-mobile">
      Enter the OTP received on the mobile number ending with{" "}
      <span className="text-highlight">{mobileNumberMessage}.</span>
    </h1>
  ) : (
    <h1>Enter the OTP received on your registered mobile number</h1>
  );

  return (
    <div className="form-section">
      <img
        className="fixed-logo-new"
        src="https://decentro-icons.s3.ap-south-1.amazonaws.com/forget-password-dashboard/decentro-blue-bg-logo.svg"
        alt="logo"
      />
      {VerifyHeaderText}
      <div className="input-section">
        <form onSubmit={handleSubmit} autoComplete="off">
          <div className="otp-field-wrapper">
            <input
              className="type-otp"
              type="text" // Use type="text" to allow numeric input
              name="otp"
              maxLength={6}
              placeholder="Enter your OTP"
              value={userData.otp}
              onChange={handleChange}
              required
              inputMode="numeric" // Restrict input to numeric keyboard on mobile devices
            />
          </div>
          <span className="error" id="otp_error">
            {errors.otp}
          </span>
          <div className="otp-hint-text">
            <p className="attempts-text">
              {otpAttemptCount === 0
                ? "All OTP attempts are exhausted. Please try again."
                : `You have ${otpAttemptCount} attempts to enter the correct OTP.`}
            </p>
            <p className="attempts-text">
              {resendAttemptCount === 0
                ? "All resend attempts are exhausted. Please try again later."
                : ``}
            </p>
            <div className="resend-btn-container">
              {resendAttemptCount === 1 ? (
                <button type="button" className="resend-btn">
                  <span onClick={toLoginOTP} className="resend-text">
                    Try Again
                  </span>
                </button>
              ) : (
                <button
                  type="button"
                  className="resend-btn"
                  onClick={handleClick}
                  disabled={isButtonDisabled}
                >
                  {isButtonDisabled ? (
                    <span className="resend-timer-text">
                      Resend in{" "}
                      <span className="resend-timer-count">{timer}s</span>
                    </span>
                  ) : (
                    <div className="resend-otp-container">
                      <span onClick={resendOTPApi} className="resend-text">
                        Resend OTP
                      </span>
                    </div>
                  )}
                </button>
              )}
            </div>
          </div>

          <button className="login-button" type="submit">
            {btnText === "loading" ? "Loading ..." : "Login"}
          </button>
        </form>
        {/* <div className="signup-message">
          <p>
            Don't have an account yet? <Link to="/signup">SIGN UP</Link>
          </p>
        </div> */}
      </div>
    </div>
  );
};

export default OTPVerify;
